// 키 밸류 라인
.key-value-line {
  display: flex;
  width: 100%;
  margin-top: 10px;

  .title {
    text-align: left;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: #576086;

    width: 50%;
  }

  .value {
    text-align: left;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    color: #000000;

    width: 50%;
  }
}

// ============================================
// ============================================

// 검색결과 박스
.result-box-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .result-box-containor-not-found {
    position: relative;
    top: 50px;
    height: 240px;

    .not-found-message {
      position: relative;
      top: 10px;

      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;

      text-align: center;

      color: #b4bccd;
    }
  }

  .result-box-containor-found {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    width: 100%;
    max-width: 500px;
    margin-top: 60px;

    .result-box-containor-contents {
      display: flex;
      flex-direction: column;
      padding: 30px;
      width: 100%;

      .img-containor {
        position: relative;
        top: -80px;
        margin-bottom: 30px;
        height: 0px;

        .box-image {
          left: auto;
          width: 108px;
        }
      }

      .result-title {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        color: #fa4666;

        margin-bottom: 40px;
      }

      .phone-number-title {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        text-align: center;

        color: #576086;
      }

      .phone-number {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;

        text-align: center;

        color: #383d55;
      }

      .record-box {
        margin-top: 20px;
        padding: 8px 0 20px 20px;
        background: #f4f5f7;
        border-radius: 12px;
      }
    }
  }
}
