.admin-containor {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f5f8;
  height: 100%;

  // 입력 팝업
  .add-tracking-modal-dim {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.337);

    .add-tracking-modal {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      overflow: hidden;
      margin: auto;
      border-radius: 12px;
      width: 800px;
      height: 600px;

      .input-lines {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        padding: 30px;
        height: 90%;

        .header-containor {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #7289aa;
          height: 24px;
          background-color: white;
        }

        // 입력 라인
        .line {
          display: flex;
          align-items: center;
          height: 30px;

          &:nth-of-type(odd) {
            background-color: rgb(242, 243, 244);
          }
        }

        .col {
          padding: 4px;
        }

        .no {
          width: 20px;
        }

        .ph-no {
          width: 140px;

          input {
            width: 130px;
            border: #c9ced3 1px solid;

            &::placeholder {
              color: #a7b0bc;
            }
          }
        }

        .track-no {
          width: 140px;

          input {
            width: 130px;
            border: #c9ced3 1px solid;

            &::placeholder {
              color: #a7b0bc;
            }
          }
        }

        .del-comp {
          width: 200px;
          font-size: 12px;
        }

        .date {
          width: 140px;
        }
      }
    }

    .btn-area {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0;

      height: 10%;
      background-color: rgb(255, 255, 255);

      .popup-control {
        flex: 1;
        padding-left: 280px;

        .button {
          margin: 2px;
          border: 0;
          padding: 12px;
          border-radius: 8px;
        }
      }

      .line-control {
        padding-right: 12px;

        .button {
          background-color: white;
          border: 1px rgb(142, 148, 164) solid;
          color: rgb(124, 130, 147);
          margin-left: 4px;
          padding: 4px 8px;
          border-radius: 4px;

          &:active {
            background-color: #d6dce5;
          }
        }
      }

      .register {
        background: #fa4666;
        font-weight: 600;
        color: white;
      }

      // .delete-line {
      //   margin-left: 60px;
      // }
    }
  }

  .header-area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    max-width: 600px;

    .register-button {
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 4px;

      width: 135px;
      height: 36px;

      background: #fa4666;
      border-radius: 8px;

      flex: none;
      order: 1;
      flex-grow: 0;

      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;

      color: #ffffff;
    }
  }

  .tracking-list-table {
    min-width: 600px;
    max-width: 600px;
    background-color: white;
    padding: 12px;
    border-radius: 12px;
    margin-top: 10px;
    margin-bottom: 60px;
    box-shadow: 0px 10px 20px rgba(28, 35, 54, 0.05);

    th {
      text-align: left;
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      padding-bottom: 12px;

      color: #acb2c0;
    }

    td {
      button {
        border: none;
        color: #7289aa;
        border-radius: 8px;
      }
    }

    .col {
      padding-top: 8px;
      padding-bottom: 8px;

      .phone-number {
        width: 140px;
      }
    }
  }
}
