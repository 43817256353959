@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

.containor {
  background-color: #f3f4f8;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw;
  height: 100vh;

  .search-area {
    background-color: #fa4666;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    z-index: 100;

    .logo-area {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 30px;

      p {
        position: relative;
        font-size: 16px;
        color: #ffffff;
        opacity: 0.6;
        top: -10px;
      }

      .sub-title {
        margin-top: 20px;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        display: flex;
        flex-direction: column;
        align-items: center;

        color: #ffffff9c;
      }

      .del-type-radio-containor {
        display: flex;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 999px;
        margin-top: 16px;
        padding: 4px;

        color: white;
        font-weight: 600;

        label {
          padding: 12px 24px 12px 12px;
          // border: black 1px solid;
          border-radius: 999px;

          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
          }
        }

        .selected {
          background: #fa4666;
        }
      }
    }

    .search-box {
      background-color: rgb(255, 255, 255);
      position: relative;
      display: flex;
      border-radius: 12px;
      overflow: hidden;
      width: 100%;
      top: 45px;
      max-width: 500px;
      box-shadow: 0px 10px 16px rgba(11, 35, 63, 0.15);

      input {
        border: 0;
        outline: none;
        flex-grow: 1;
        padding: 20px;
        font-size: 20px;
        letter-spacing: 0.06em;
        font-weight: 700;
        width: 30px;
      }

      input::placeholder {
        font-weight: 500;
        color: #d0d0d6;
        letter-spacing: -0.01em;
      }

      button {
        background-color: rgb(255, 255, 255);
        width: 60px;
        border: 0;
      }
    }
  }

  .result-area {
    display: flex;
    overflow: scroll;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 20px;
    padding-top: 50px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
